<template>
    <div>
        <b><i></i></b>
        <h3>Introduction</h3>
        <p>
            Welcome to <b><i>Lucid Genomics Platform</i></b> Privacy Notice.
        </p>
        <p>
            At <b><i>Lucid Genomics</i></b> (“us”, “we”, “our”), we work to help clinical experts or researchers
            (“Users”) in
            analyzing genetic data (e.g. gene panel, exome sequencing, genome sequencing) thanks to an innovative
            solution, <b><i>Lucid Genomics Platform, our AI-powered platform</i></b> for automated bioinformatic
            analysis.
        </p>
        <p>
            The usage of our platform involves the processing of User’s personal data.
        </p>
        <p>
            “Personal data” is any information relating to an identifiable living individual. This encompasses
            information that directly identifies a person (like their name or address) as well as indirect identifiers
            (random IDs) that, when combined with other pieces of information, could reasonably be used to pinpoint a
            specific person].
        </p>

        <h3>What will you find in this document?
        </h3>
        <p>
            This Privacy Notice (“Notice”) sets out the terms under which we handle your personal data while you use our
            software in accordance with article 13 of the General Data Protection Regulation (“GDPR”). Your privacy is
            very important to us and the GDPR allows us to protect your personal information.
            <br />

            Personal data collected through our platform will be processed solely and exclusively for the purposes
            described in this document and will not be used for any other purpose.


        </p>

        <h3>Who processes your personal data?</h3>
        <p>
            The Data Controller is:
        </p>
        <p class="font-weight-bold font-weight-italic">
            Lucid Genomics GmbH
            <br />
            Machnowerstr 64, 14165 Berlin, Germany
        </p>
        <p>
            You can contact us at any time by sending an email to <i> <b>contact@lucid-genomics.com</b></i> and by phone
            to
            <i> <b>+49 176 59706707</b></i>
        </p>

        <h3>Data protection Officer</h3>
        <p>
            To further strengthen your data protection, we've appointed a dedicated Data Protection Officer (DPO) who
            you can contact if you have any questions about how your personal data is processed, or if you believe your
            privacy rights may have been infringed. You can contact our DPO by sending an email at
            <i> <b>lucidgenomics-dpo@chino.io</b></i>

        </p>

        <h3>What data do we process?</h3>
        <p>
            When you use our platform, we will elaborate different categories of personal data grouped as follows:

        </p>
        <p>
        <table>
            <thead>
                <tr>
                    <th>Categories of personal data processed</th>
                    <th>Examples of data processed</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Identification data</td>
                    <td>
                        First and last name
                        <br />
                        Login credentials
                    </td>
                </tr>
                <tr>
                    <td>Education, Profession, and Employment data</td>
                    <td>Reference Company/ Institute/ Organisation/Hospital/University</td>
                </tr>
                <tr>
                    <td>Usage data</td>
                    <td>
                        Usage logs
                        <br />
                        Errors experienced
                        <br />
                        Feedbacks
                    </td>
                </tr>
            </tbody>
        </table>

        </p>

        <h3>Why do we process your personal data?</h3>
        <p>
            We can process your personal data for the following purpose:
        </p>
        <h4>
            1) Provide you with our platform
        </h4>
        <p>
        <table>
            <thead>
                <tr>
                    <th>Processing activities</th>
                    <th>Categories of data processed</th>
                    <th>Legal basis for processing</th>
                    <th>Are you required to provide the data?</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Account creation and management</td>
                    <td>Identification data
                        <br />
                        Education, Profession and Employment Data
                    </td>
                    <td>Article 6 (1)(b) - GDPR </td>
                    <td>The provision of this data is mandatory to use our service</td>
                </tr>
            </tbody>
        </table>
        </p>

        <h4>2) Ensure system security </h4>
        <p>
        <table>
            <thead>
                <tr>
                    <th style="width: 25%;">Processing activities</th>
                    <th> Categories of data processed</th>
                    <th style="width: 40%;">Legal basis for processing</th>
                    <th>Are you required to provide the data?</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <ul>
                            <li>Log user’s interactions with our platform</li>
                            <li>Throttle suspicious activities</li>
                            <li>Logging API requests</li>
                            <li>Logging bioinformatic analysis progress</li>
                            <li>Error logging</li>
                        </ul>
                    </td>
                    <td>Usage data</td>
                    <td>Article 6 (1)(f) - GDPR
                        <br />
                        The collection and processing of user’s data (errors, logs) has been deemed necessary to
                        identify and resolve any system malfunctions, thus ensuring a quality and secure service for
                        users. The company has assessed that this legitimate interest prevails, considering the
                        non-sensitive nature of the data collected and the absence of profiling or other activities that
                        could harm users' privacy
                    </td>
                    <td>
                        This data is collected automatically when you use our platform. You can oppose this process at
                        any time by contacting us as explained in section “How do we support you in exercising your
                        rights”.
                    </td>
                </tr>
            </tbody>
        </table>
        </p>


        <h4> 3) Fine Tuning AI models and software improvement</h4>
        <p>
        <table>
            <thead>
                <tr>
                    <th style="width: 25%;">Processing activities</th>
                    <th>Categories of data processed</th>
                    <th style="width: 40%;">Legal basis for processing</th>
                    <th>Are you required to provide the data?</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <ul>
                            <li>Logging user interactions with the UI to improve user experience</li>
                            <li>Storing user feedback on the analysis result to improve and fine-tune AI models</li>
                        </ul>
                    </td>
                    <td>Usage data</td>
                    <td>Article 6 (1)(f) - GDPR
                        <br />
                        The collection and analysis of user’s feedback is deemed necessary to enhance product
                        functionalities and meet customer expectations. This legitimate interest outweighs the minimal
                        privacy implications, considering the nature of the data involved (i.e. feedback on product
                        functionalities, labeling)
                    </td>
                    <td>
                        This data is collected automatically when you use our platform. You can oppose this processing
                        at any time by contacting us as explained in section “How do we support you in exercising your
                        rights”.
                    </td>
                </tr>
            </tbody>
        </table>
        </p>

        <h3>Do we disclose your data to anyone?</h3>
        <p>
            We will not disclose personal data to third parties, unless such disclosure is necessary to carry out
            certain processing activities. In such a case, we ensure that our suppliers, acting as Data Processors under
            Article 28 GDPR, will only process your personal data under appropriate confidentiality and security
            obligations and in accordance with our instructions and with this Notice.
        </p>

        <h3>Do we transfer your data outside the EU/EEA?</h3>
        <p>
            Your personal data will be processed within the European Union and the European Economic Area. Should it be
            necessary for your personal data to be transferred to third countries outside the EU/EEA, such data
            transfers will take place only to third countries with an Adequacy Decision in place approved by the
            European Commission or, in case of no Adequacy Decision, based on Standard Contractual Clauses provided by
            the European Commission and additional security measures to ensure data security.
        </p>

        <h3>How long do we retain your personal data?</h3>
        <p>
            We will retain your personal data only as long as necessary to fulfil the purposes for which we collected
            it, including purposes related to fulfilling legal, tax or accounting obligations.


            <br />
            We will retain the personal data we process to provide you with the services for the whole duration of the
            service. Data processed to ensure system security will be kept from 6 months to maximum one year.
            <br />

            A longer retention period may be required in the event of a legal complaint or in the event of disputes
            related to our existing contractual relationships. To determine the retention period of personal data we
            take into account:


            <br />

        <ul>
            <li>the purposes for which we process the personal data, and whether we can achieve those purposes by other
                means. </li>
            <li>the amount, nature and sensitivity of the data processed;</li>
            <li>the potential risk of harm to individuals derived from unauthorized use or disclosure;</li>

        </ul>
        If personal data is no longer necessary for the purposes, or legitimate interests, pursued by us and no other
        legal basis applies, we will delete your data.
        </p>

        <h3>How do we support you in exercising your rights?</h3>
        <p>
            We inform you that you have the right to:

        <ul>
            <li>Ask us whether or not your personal data is being processed, and if so, request access or ask for copies
                of your personal data. You can also ask us for further information related to this Notice.</li>
            <li>Ask us to rectify information you think is inaccurate. You also have the right to ask us to complete
                information you think is incomplete;</li>
            <li>Ask us to erase your personal data in certain circumstances.</li>
            <li>Object, under certain circumstances, to the processing of personal data, for reasons relating to your
                specific situations.</li>
            <li>Not be subjected to automated decision-making, including profiling.</li>
            <li>Withdraw consent given at any time (if consent is the legal basis for processing);</li>
            <li>Receive personal data in a standardized format in case you wish to transfer them to another data
                controller (data portability);</li>
            <li>Lodge a complaint at any time with the Data Protection Authority, in case of violation of data
                protection rights;</li>
            <li>Ask us to restrict the processing of your information in certain circumstances.</li>
            <li>Request that any of the above changes be communicated to other parties to whom the data has been
                disclosed.</li>

        </ul>

        The exercise of rights is not subject to any formal constraints and is free of charge.

        <br />
        You can exercise your rights by contacting us and our DPO at the following address <b><i>
                lucidgenomics-dpo@chino.io</i></b>
        </p>

        <h5>Updates</h5>
        This Notice may be subject to changes and reviews. All personal data processed is subject to the Notice
        effective at the time it was collected. Any changes to this Notice will be posted on Lucid Genomics platform
        and, if necessary, notified to you. We encourage you to consult our website periodically so that you are always
        informed about how we process your personal data. This Notice was last updated on 23/10/2024.


    </div>
</template>
<script>
export default {
    name: "PrivacyPolicyContents",
};
</script>
<style scoped>
h3 {
    color: var(--v-primary-base);
    margin-bottom: 4px;

}

h4 {
    margin-bottom: 4px;

}
p {
    padding-left: 12px;
}

table {
    border-collapse: collapse;

}

td,
th {
    padding: 4px 20px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
}

th {
    background-color: var(--v-primary-base);
    color: white;
}
</style>