<template>
    <v-card flat>
        <v-card-title class="text-h4 font-weight-medium primary--text pa-8">Privacy Notice for Users        </v-card-title>
        <v-card-text class="pt-4 px-16">
            <PrivacyPolicyContents></PrivacyPolicyContents>
        </v-card-text>
    </v-card>
</template>
<script>
import PrivacyPolicyContents from "@/components/PrivacyPolicyContents.vue"
export default {
    name: 'PublicPrivacyPolicy',
    components: { PrivacyPolicyContents },

}
</script>